import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Heike from "../../components/pages/heike-kabisch/heike-kabisch"
import AccessWrapper from '../../components/pages/AccessWrapper'


const HeikeKabischPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  return(
    <Layout>
      <SEO title="Home" />
        <AccessWrapper introString='Heike Kabisch' content={content.artists[3]} id={3}>
          <Heike video={content.heike_video} images={content.heike_images} />
        </AccessWrapper>
    </Layout>
  )
}

export default HeikeKabischPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`