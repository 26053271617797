import React, { useState } from "react";
import style from './heike-kabisch.module.scss';
import VideoPlayer from '../../elements/videoPlayer';
// import Slideshow from '../../layout/slideshow';
import Slideshow from '../../layout/slideshowNew';
import { SwiperSlide } from 'swiper/react';
import Image from '../../elements/image';

const HeikeKabisch = ({ video, images }) => {

    const params = {
      loop: true,
      loopedSlides: 4,
      mode: 'horizontal',
      // slidesPerView: "auto",
      // mousewheel: true,
      // activeSlideKey: 0,
      // watchSlidesVisibility: true,
      lazy: false,
      autoplay: {
        delay: 1000
      },
      navigation: {
        nextEl: style.swiperButtonNext,
        prevEl: style.swiperButtonPrev
      },
      // renderPrevButton: () => <div role="button" className="swiper-button-prev">Prev</div>,
      // renderNextButton: () => <div role="button" className="swiper-button-next">Next</div>,
    
      freeMode: true,
      // breakpoints: {
      //     1200: {
      //         freeMode: false,
      //     },
      // },
      shouldSwiperUpdate: true,
    }

    return(
        <div className={style.heike}>
          <VideoPlayer video={video} padding={0}/>
          {/* <div className={style.background}>
            <VideoPlayer video={video} />
          </div> */}
          {/* Slideshow */}
          <Slideshow params={params}>
          {
            images.map((image, i) => (
              <SwiperSlide key={i}>
                <div className={style.heikeImage}>
                  <Image src={image.filename} />
                </div>
              </SwiperSlide>
             
            ))
          }
           {/* <div key={i} className={style.heikeImage}>
                <Image src={image.filename} />
              </div> */}

          </Slideshow>
          {/* <Slideshow images={images} /> */}
        </div>
    )
}

export default HeikeKabisch

